@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
/* DONT DELTE OR MODIFY THEM UNDER NO CIRCUMSTANCE: INCLUDES CUSTOMER CUSTOM RULES */

body {
  /* font-family: 'Source Sans Pro', sans-serif; */
  /*font-family: 'Oswald', sans-serif; */
  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
}

.hhj {
  color: #002663 !important;
  background-color: white !important;
}

.hhj a {
  color: #002663 !important;
}

.hhj:hover a:hover {
  color: #ff6b00 !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --background: #12161d;
  --card: #fff;
  --cardStroke: #242424;
  --darkGray: #2f353d;
  --gray: #808890;
  --blueSilver: #d2dcdf;
  --silver: #1a4f74;
  --white: #f7f8fa;
  --brandHover: #fc4d96;
  /* --brandHover: #d79732; */
  --brand: #161eaf;
  --brandSecondary: #fc4d96;

  --mainBlack: black;
  --mainWhite: white;

  --mainRoyalBlue: #002663;
  --mainLightBlue: #a5c9e7;
  --mainBeige: #e8e2d5;
  --mainLightRed: #f2b7bf;

  --mainContainerBackground: #f9faff;
  /****** CATEGORY SLIDERS ******/

  /*Category Title*/
  --mainSliderCategoryTitle: #002663;

  /*View all button*/
  --mainCategoryViewAll: #002663;
  --mainCategoryViewAllHover: #1c26dc;

  /********************************/

  /*Social media*/
  --socialMediaIconColor: #002663;
  --socialMediaIconColorHover: #a5c9e7;

  /* Details */
  --detailsContainerBackgroundColor: transparent;

  --folderNamesColor: #fc4d96;
  --folderNamesColorHover: #a5c9e7;

  --descriptionColor: var(--silver);

  /******* NAVBAR *********/

  --navBarBackgroundColor: #002663;
  --navBarButtonColor: #e5edf0;
  --navBarButtonSelectedColor: #fc4d96;

  /****** ASSET ITEM ******/
  --mainItemTitle: #002663;
  --mainItemFolderNames: #212121;

  --itemTitleDescriptionDuration: #161eaf;

  --itemTitle: #1a4f74;
  --categorytitle: #002663;

  --mainPurple: #002663;
  --mainRed: #e66342;
  --mainOrange: #d79732;
  --mainLightGreen: #d1e57c;
  --mainDarkGreen: #01917a;

  --brandPressed: #172bb5;
  --red: #be3037;
  --disabledText: #a1a9b0;
  --font40: 40px;
  --font39: 39px;
  --font38: 38px;
  --font37: 37px;
  --font36: 36px;
  --font35: 35px;
  --font34: 34px;
  --font33: 33px;
  --font32: 32px;
  --font31: 31px;
  --font30: 30px;
  --font29: 29px;
  --font28: 28px;
  --font27: 27px;
  --font26: 26px;
  --font25: 25px;
  --font24: 24px;
  --font23: 23px;
  --font22: 22px;
  --font21: 21px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font14: 14px;
  --font12: 12px;
  --font11: 11px;
  --font10: 10px;
  --font9: 9px;
  --font8: 8px;
  --font7: 7px;
  --font6: 6px;
  --font5: 5px;
}

h2 {
  font-size: 24px;
  font-size: var(--font24);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #161eaf;
  color: var(--brand);
}
.itemTitleHeadingColor {
  color: #1a4f74 !important;
  color: var(--itemTitle) !important;
}
.categoryColor {
  color: #fc4d96 !important;
  color: var(--brandHover) !important;
}
.display-flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
h3 {
  font-size: 36px;
  font-size: var(--font36);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
  color: #161eaf;
  color: var(--brand);
}

h2::after,
h1::after {
  content: '';
  width: 120px;
  height: 4px;
  top: -1rem;
  display: block;
  background-color: #161eaf;
  background-color: var(--brand);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.slick-prev {
  z-index: 1 !important;
}
.copy-popup {
  background: white;
  background: var(--mainWhite);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  min-width: 60%;
  min-height: 40vh;
}

.slick-prev:before {
  font-size: 2.5rem !important;
  opacity: 1;
}
.slick-next:before {
  font-size: 2.5rem !important;
  opacity: 1;
}

/* / slick settinng for tampere kauppakamari */
.carousel .slide {
  padding: 0 !important;
}

/* // end of settings for tampere kauppakamari */
button {
  padding: 10px 56px;
  text-decoration: none;
  border: none;
  font-size: 16px;
  font-size: var(--font16);
  border-radius: 12px;
}
button:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
button:disabled {
  background: #808890;
  background: var(--gray);
  color: #d2dcdf;
  color: var(--blueSilver);
  cursor: not-allowed;
}
button:disabled:hover {
  background: #808890;
  background: var(--gray);
  color: #d2dcdf;
  color: var(--blueSilver);
  cursor: not-allowed;
}

.react-multiple-carousel__arrow {
  padding: inherit;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  top: 22%;
}
.react-multi-carousel-list {
  /* justify-content: center !important; */
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #161eaf;
  color: var(--brand);
  font-size: 26px !important;
  bottom: 0;
  margin-top: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  height: 50px;
  align-items: center;
  top: 50% !important;
  opacity: 1 !important;
  border-radius: 50px;
}
.carousel .slide {
  padding: 0 20px;
}

/* changing right and left button in banner color */
.carousel.carousel-slider .control-arrow:hover {
  background: #fc4d96 !important;
  background: var(--brandHover) !important;
}

/* Banner carousel */

.carousel .control-prev.control-arrow {
  left: 30px !important;
}
.carousel .control-next.control-arrow {
  right: 30px !important;
}
.react-multiple-carousel__arrow {
  padding: inherit;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  top: 22%;
}
/* // banner slider custom button */
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  top: 50% !important;
  min-width: 43px !important;
  min-height: 43px !important;

  height: 43px !important;
  border-radius: 50% !important;
  background: #161eaf !important;
  background: var(--brand) !important;
  opacity: 0.8 !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin: 0 !important;
  font-family: 'revicons' !important;
}
.carousel .control-next.control-arrow:before {
  content: '\e825' !important;
  border: none !important;
}
.carousel .control-prev.control-arrow:before {
  content: '\e824' !important;
  border: none !important;
}

.hero-container {
  width: 95%;
  margin: 0 5%;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  z-index: 7;
}

.menu-item a {
  position: relative;
  display: block;
  color: #1a4f74;
  color: var(--silver);
  text-decoration: none;
  cursor: pointer;
}

.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a {
  color: #fc4d96 !important;
  color: var(--brandHover) !important;
}
.menu-item .sub__menus li:hover a {
  color: #fc4d96 !important;
  color: var(--brandHover) !important;
}

@media (max-width: 991px) {
  .main-nav .menubar__button:hover {
    background-color: #fc4d96;
    background-color: var(--brandHover);
    color: #161eaf;
    color: var(--brand);
  }
  .main-nav .menu-item:hover > a {
    color: #fc4d96 !important;
    color: var(--brandHover) !important;
  }
  .main-nav .menubar__button {
    display: block !important;
    float: right;
    background-color: #f7f8fa;
    background-color: var(--white);
    color: #161eaf;
    color: var(--brand);
    padding: 6px 7px;
    border-radius: 5px;
    margin: auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10007;
    background-color: #12161d;
    background-color: var(--background);
    border-bottom: 3px solid #161eaf;
    border-bottom: 3px solid var(--brand);
    right: 0;
    padding-bottom: 17px;
    padding-top: 57px;
    grid-gap: 10px;
    gap: 10px;
  }

  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
}

/* // image wrapper for aspect-ratio 16/9
 */
.image_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.image_wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.font-800 {
  font-size: 40px;
  font-size: var(--font40);
  font-weight: 600;
  opacity: 1 !important;
  margin-bottom: 20px;
}

.font-700 {
  font-size: 36px;
  font-size: var(--font36);
  font-weight: bold;
}
.font-600 {
  font-size: 24px;
  font-size: var(--font24);
}

.font-500 {
  font-size: 20px;
  font-size: var(--font20);
  line-height: 20px;
}

.font-400 {
  font-size: 18px;
  font-size: var(--font18);
}

.font-300 {
  font-size: 16px;
  font-size: var(--font16);
}
.font-200 {
  font-size: 14px;
  font-size: var(--font14);
}
.font-100 {
  font-size: 12px;
  font-size: var(--font12);
}
.font-000 {
  font-size: 11px;
  font-size: var(--font11);
}
/* media Queeries */
@media (max-width: 850px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    font-size: 22px;
    font-size: var(--font22);
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }
  .hero-container {
    width: 100%;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  }
}
@media (max-width: 700px) {
  .font-800 {
    font-size: 20px;
    font-size: var(--font20);
  }
  .font-700 {
    font-size: 16px;
    font-size: var(--font16);
  }
  .font-600 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-500 {
    font-size: var(--font13);
  }
}
/* media Queeries */
@media (max-width: 600px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    /* font-size: var(--font22); */
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }

  /* button {
    padding: 18px 20px;
  } */
}

.activeSeason {
  background: #2671fe !important;
  background: #fc4d96 !important;
  background: var(--folderNamesColor) !important;
  color: #e5edf0 !important;
  color: #1a4f74 !important;
  color: var(--silver) !important;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .overlay_blur {
  background-image: linear-gradient(
    270deg,
    #12161d 0%,
    rgba(18, 22, 29, 0.08) 100%
  );

  opacity: 1;

  z-index: 100000;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .playicon_overlay {
  display: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 90;
  background-color: #ddd;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
}
.modal > h2 {
  padding: 20px;
  margin: 0;
  color: #161eaf;
  color: var(--brand);
  /* border-bottom: 1px solid #555; */
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.99);
  z-index: 100;
}

.selected {
  color: var(--brand) !important;
}

.navBarBTN:hover {
  text-decoration: underline;
  cursor: pointer;
}
.categories_info {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.navBarSiteLogo {
  height: 38px;
  justify-content: center;
  align-items: center;
}

.navBarSiteLogo:hover {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  /*background: linear-gradient(to right, #000000, #171d31, #000000);*/
  /* background: #12161d; */
  /*
  background-image: linear-gradient(to right, #000000, #171d31, #000000);
  background-image: -moz-linear-gradient(to right, #000000, #171d31, #000000);*/
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #f9faff;
}

.item {
  transition: all 0.3s ease-in-out;
}

.chosenCategoryGrid {
  flex-grow: 1;
  height: 100%;
}

.categoryItemTitle {
  background-color: #646464;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.7rem 0.5rem 0.7rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  text-align: center;
  overflow: hidden;
  width: 15rem;
}

.categoryItemImg {
  object-fit: contain;
  display: block;
  max-height: 24rem;
  max-width: 16rem;
  /*min-height: 24rem;*/
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/*
.categoryItem:hover {
  transform: scale(1.1);
}
*/

.detailsUpper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 25%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 450px; */
}

.detailsUpperBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 100% 450px; */
}

.detailsUpperSmall {
  width: 80%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.detailsUpperCover {
  width: 100%;
  vertical-align: middle;
}

.detailsPlayContainer {
  background-color: var(--brand);

  text-decoration: none;
  margin-bottom: 20px;
  color: var(--white);
  border: none;
  text-transform: uppercase;
  border-radius: var(--font12);
  line-height: 130%;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.detailsPlayContainer:hover .svg-play {
  fill: var(--brand);
}
.detailsPlayContainer:hover {
  cursor: pointer;
  background: var(--brandHover);
  color: var(--brand);
}

.svg-play {
  fill: white;
}

.detailsUpperCoverContainer {
  display: inline-block;
  width: 20%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.categoryContent,
.similiarCategoryContent {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.categoryContent::-webkit-scrollbar {
  display: none;
}

.banners {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.catArrow {
  color: white;
  width: 2rem;
  position: relative;
  z-index: 100;
  align-self: center;
  cursor: pointer;
  font-size: 3rem;
  transition: all 0.3s ease-in-out;
}

.catArrow:hover {
  background-color: rgba(20, 20, 20, 5);
  box-shadow: 0px 0px 1rem #fff;
  border-radius: 1rem;
}

.arrowRight {
  margin-left: auto;
  margin-right: 1rem;
}

.arrowLeft {
  margin-left: 1rem;
}

.categoryTitleContainer,
.similiarCategoryTitleContainer {
  margin: 1.5rem 0 1.5rem 0;
  color: #fcca13;
  font-family: 'Brandon', Tahoma, sans-serif;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.similiarContainer {
  margin-top: 1.5rem;
}

.categoryTitle {
  text-align: center;
  cursor: default;
}

.titleHover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.faqContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
}

.playVideoContainer {
  /*
 margin: 0 auto 0 auto;
  width: 100%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
  */

  overflow: hidden;
  /*
  position: relative;
  padding-top: 56.25%;
  */
}
/*
.maxContainer {
  display: flex;
  max-height: 100vh;
  height: 100vh;
  width: 80%;
  background-color: black;
  margin: 0 auto;
}
*/
.maxContainerPrimary {
  width: 100%;
  background: black;
  position: relative;
  /* padding: 10px 0; */
}
.embedButton {
  position: absolute;
  /* // should be 40px all the time */
  bottom: 40px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand);
  /* padding: 0px 10px; */
  color: var(--silver);
  padding: 10px;
  opacity: 0;
}
.embedButton:hover {
  cursor: pointer;
}
.embedButton > * + * {
  margin-left: 10px;
}

.maxContainer {
  width: 70%;
  margin: 0 auto;
}
.maxContainerEmbed {
  width: 100%;
  margin: 0 auto;
  max-height: 100vh;
  /* overflow: hidden; */
}
.maxContainerPrimary:hover .embedButton {
  opacity: 1;
}

/*
.radiantPlayer {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.radiantPlayer .rmp-control-bar {
  margin-top: -200px;
}
*/

.searchColumn {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
}

.searchButton {
  height: 50px;
}

.searchField {
  position: relative;
  padding: 17px 20px 17px 15px;
  outline: none;
  color: var(--background);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 500;
  max-height: 50px;
  min-height: 50px;
  width: 400px;
}

.searchRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  align-items: center;

  justify-content: space-between;
}

.searchCheckbox {
  margin-right: 0.25rem;
}

.searchCheckboxLabel {
  margin-right: 1%;
}

.resultsAmount {
  margin-left: 10%;
  margin-top: 1%;
  font-size: 16px;
  margin-right: 20%;
}

.searchResultBTN {
  background: var(--silver);
}

/* .active {
  background: var(--brand) !important;
} */
.searchResultBTN:hover {
  background: var(--brandHover);
}

.searchLabel {
  margin-bottom: 0.25rem;
  height: 18px;
}

.searchSelectCategory {
  padding: 0.25rem;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
  width: 80%;
  margin: 0 auto;
}

.searchTitle {
  font-size: 28px;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.slick-track {
  display: flex !important;
  grid-gap: 10px;
  gap: 10px;
}

.videoContainer {
  padding-top: 5%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.details-description-container {
  width: 80%;
  margin: 0 auto;
  background-color: #1e2228;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
}

.detailsDescriptionTitleContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #fcca13;
  padding: 10px;
  overflow: hidden;
}

.details-description-title-left {
  display: flex;
  flex-direction: column;
  width: 70%;
  float: left;
}

.details-description-title-right {
  max-width: 30%;
  margin-left: auto;
}

.details-description-title-name {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: auto;
}

.folderNamesList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
}

.folderName {
  color: white;
  font-size: 20px;
  margin-right: 0.5rem;
}

.ratingElement {
  width: 50px;
}

.details-desription-mobile-cover {
  display: none;
}

.details-description-info-container {
  /* margin: 20px auto; */
  display: flex;
}

.detailsContainer {
  /* font-family: 'Brandon', Tahoma, sans-serif; */
}

.detailsInfoContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.infoDataContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--silver);
}

.dataTitle {
  font-weight: bold;
  margin-right: auto;
}

.dataValue {
  margin-right: auto;
  padding: 10px 0px;
}

.shareBTN {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
}

.details-share {
  display: flex;
  padding-top: 35px;
  flex-wrap: wrap;
  width: 100%;
}

.isClicked {
  max-height: 100% !important;
  height: 100% !important;
  /* overflow: scroll; */
}

.detailsDescriptionContainer {
  flex: 3 1;
}

.svg-triangle {
  width: 26px;
  height: 20px;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.categoryGridContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.resultsGrid {
  width: 100%;
  margin-top: 1.5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.categoryItem {
  /* max-width: 17rem; */
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0.25rem;
}

.categoryItemPlaceholder {
  /* max-width: 17rem; */
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0.25rem;
  display: flex;
  justify-content: center;
}

.promoContainer {
  margin: 0 auto;
  overflow: hidden;
}

.promoItem {
  box-sizing: border-box;
  width: 33.333%;
  padding: 0.2rem;
}

.promoItemImg {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.bannerItem {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.categoryItem:hover {
  cursor: pointer;
}

.footerLine {
  border-top: 2px solid #f6c917;
  margin: 20px 0;
}

.bottomBar {
  width: 100%;
  height: 120px;
  padding: 0 10% 1% 10%;
  display: table;
  color: white;
  z-index: 5;
}

.footerInfo {
  padding-left: 20px;
  display: inline-block;
}

.footerInfoName {
  font-size: 18px;
  margin-bottom: 15px;
}

.footerInfoAddress {
  font-size: 12px;
  float: left;
  margin-right: 180px;
}

.footerInfoPhoneEmail {
  display: inline-block;
  font-size: 12px;
}

.footerTos {
  font-size: 12px;
  float: right;
  padding-right: 20px;
}

.footerTos:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tosContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  flex-grow: 1;
  color: white;
  font-size: 12px;
}

.SVOD-container,
.TVOD-container {
  margin: 0.5rem;
  width: 10rem;
  height: 10rem;
  background-color: #fcca13;
  text-align: center;
}

.SVOD-container:hover,
.TVOD-container:hover {
  cursor: pointer;
}

.main-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70.5%;
  justify-content: center;
}

.categoriesDropdown {
  flex-direction: column;
  position: absolute;
  max-height: 15rem;
  background: var(--card);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* right: 0%; */
  border-radius: 0.25rem;
  z-index: 100000000000;
  color: var(--silver);
  /* width: 187px; */
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
}
.categoriesDropdown > * + * {
  margin-top: 10px;
}
.categoriesDropdown a,
.categoriesDropdown li {
  text-decoration: none;
  color: var(--silver);
  cursor: pointer;
  /* // dont remove font-size */
  font-size: 14px;
}
.categoriesDropdown a:hover,
.categoriesDropdown li:hover {
  text-decoration: none;
  color: var(--brandHover);
  /* background-color: var(--brandHover); */
}

.categoriesBar {
  display: flex;
  margin-top: 1rem;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.categoriesButtonContainer {
  display: inline-block;
  position: relative;
  color: white;
  font-size: 24px;
}

.categoriesButton {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.svg-chevron {
  margin-left: 0.5rem;
}

.dropdownOption {
  padding: 0.25rem 2rem 0.25rem 1.5rem;
  text-align: start;
  width: 100%;
  cursor: pointer;
  font: 82.5%/1.8 Arial, Helvetica, Verdana, sans-serif;
}

.dropdownOption:hover {
  background: #313131;
}

.loginContainer,
.signUpContainer {
  cursor: pointer;
  display: flex;
}
.loginContainer > * + * {
  margin-left: 10px;
}

.loginBTN {
  color: var(--brandHover);
  text-decoration: none;
}
.loginBTN:hover {
  color: var(--brandHover);
  text-decoration: none;
}
.active {
  color: var(--brand);
}
.modal-background {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  cursor: pointer;
  position: absolute;
  z-index: 110;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1.75rem auto;
  position: relative;
  background-color: #3d3d3d;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 2rem;
  max-width: 30rem;
  max-height: 20rem;
  z-index: 120;
  cursor: default;
}

#form_id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginFormInput {
  width: 100%;
  margin: 0.25rem 0 0.25rem 0;
}

.signUpBarInput {
  padding-left: 0.5rem;
  width: 100%;
  margin-right: 0.25rem;
}

.signUpBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
}

.signUpBar2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
  padding: 20px auto;
}

.signUpBackground {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
}

.signUpWelcomeText {
  font-size: 24px;
  width: 33.3%;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 48px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText3 {
  font-size: 28px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpRow {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  z-index: 2;
}

.loginMessage {
  height: 4rem;
}

.loginFormTOS {
  margin: 0.5rem 0 0.5rem 0;
  font-size: 18px;
  font-weight: normal;
}

.loginViewBackground {
  background-size: cover;
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  /*position: absolute;*/
  width: 100%;
  z-index: 1;
}

.loginViewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.loginFormButtons {
  display: flex;
  flex-direction: row;
}

.loginFormBTN-background {
  background: #3d3d3d;
}

.loginFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  margin-left: 10%;
}

.loginFormBTN:hover {
  background: transparent;
}
/*
.signUpBarBTN-background {
  background: #3d3d3d;
}
*/

.signUpBarBTN {
  background: rgb(125, 221, 14);
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0.5rem;
  font-weight: 300;
  border: none;
  font-size: 38px;
}

.signUpBarInfoMessage {
  background: #f6c917;
  display: flex;
  font-size: 18px;
  z-index: 5;
  color: black;
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.signUpBarBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpFormBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpForm {
  display: flex;
  flex-direction: column;
  width: 25%;
  color: white;
}

.stepsContainer {
  font-size: 20px;
}

.signUpFormInput {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.signUpStep1 {
  display: flex;
  flex-direction: column;
}

.signUpStepText {
  font-size: 20px;
  margin-bottom: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpStepTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.loginFormTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

#userDetails-form {
  display: flex;
  flex-direction: column;
}

.profile-container {
  width: 80%;
  flex-grow: 1;
  padding-top: 5%;
  margin: auto;
  display: flex;
  flex-direction: row;
  color: white;
}

.profile-menu-option {
  font-size: 18px;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.profile-menu-option:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(241, 241, 241, 0.2);
}

.profile-option-view {
  color: black;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.profile-details-row {
  display: flex;
  align-items: center;
  margin: 0.5rem auto 0.5rem auto;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.profileFormInput {
  width: 30rem;
  box-sizing: border-box;
}

.profileFormBTN {
  align-self: center;
  width: 5rem;
  margin-left: 10%;
}

.loginFirstMessage {
  font-size: 18px;
  margin: auto;
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */

.profileMessage {
  margin: 2rem auto;
}

.series-seasons-container {
  box-sizing: border-box;
  /* width: 80%; */
  margin: 0 auto;
  flex-wrap: wrap;

  /* padding: 20px; */
}

.seasonsAndEpisodesContainer {
  width: 88%;
  margin: 0 auto;
}
.seasonsAndEpisodesContainer > * {
  margin-top: 30px !important;
}
.series-seasons-header {
  /* font-family: 'Brandon', Tahoma, sans-serif; */
  color: white;
  padding: 20px 0;
}

.series-seasons-numbers {
  display: flex;
}
.series-seasons-numbers > a {
  margin-right: 5px;
}

.seasonNumber {
  background-color: var(--background);
  color: var(--white);
  border: 1px solid var(--white);
  display: flex;
  /* padding: 10px; */
  cursor: pointer;
  text-decoration: none;
  /* width: 181px; */
  margin-right: 16px;
  height: 45px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.seasonNumber:hover {
  color: var(--brandHover);
}

.series-episode-list {
  /* width: 80%; */
  margin: 0 auto;
}

.episodeDetailsMiddle {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  margin-left: 15px;
  margin-top: 5px;
}

.episodeDetailsMiddle:hover {
  cursor: pointer;
}

.episodeDetailsMiddleTop {
  display: flex;
  flex-direction: row;
  color: var(--white);
  margin: 5px 0;
  line-height: 20.8px;
}

.episodeDetailsMiddleBottom {
  margin-top: 5px;
}

.episodeDetailsLeft {
  display: block;
  margin: 0;
  padding: 0;
  height: 100px;
}

.episodeDetailsRight {
  display: flex;
  width: 11%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.episodeDetailsItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  background: var(--card);
  color: var(--blueSilver) !important;
  border-radius: 0px 10px 10px 0px;
  height: 102px;
  min-height: 102px;
  max-height: 102px;
  overflow: hidden;
}

.info-duration {
  margin-left: 20px;
}

.episode-list-img {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 100px;
  width: 177.78px;
}

.episode-list-img:hover {
  cursor: pointer;
}

.showPlayerBar {
  display: flex;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.icon-play {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}
.icon-play:hover {
  color: var(--brandHover);
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}

.episode-list-description {
  color: white;
  /* font-family: 'Brandon', sans-serif; */
  font-size: 16px;
}

.thumbnail-container {
  position: relative;
  box-sizing: border-box;
  height: 100px;
  width: 175px;
}

.informPlaceholder {
  font-size: 28px;
  color: white;
  text-align: center;
  height: 70%;
}

.play {
  /* background-image: url('../src/images/button_play_inactive.png'); */
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.play:hover {
  /* background-image: url('../src/images/button_play_active.png'); */
}

.langFlag {
  margin-right: 0.5rem;
  width: 40px;
  max-width: 40px;
  cursor: pointer;
}

.languageSelect {
  margin-left: auto;
  padding-top: 10px;
}
.categoriesContainer {
  padding-bottom: 50px;
  /* width: 95%; */
  margin: 0 auto;
}
.bannerContainer {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}

@media (max-width: 1240px) {
  .maxContainerPrimary {
    width: 100%;
    background: black;
    /* padding: 10px 0; */
  }
  .maxContainer {
    width: 100%;
  }
  .maxContainerEmbed .rmp-control-bar {
    bottom: 0px !important;
  }
}
@media (max-width: 950px) {
  .series-seasons-container {
    box-sizing: border-box;
    /* width: 90%; */
    margin: 0 auto;
    /* padding: 20px; */
  }
  .series-episode-list {
    /* width: 90%; */
  }
}
@media (max-width: 750px) {
  .bannerContainer {
    padding-bottom: 20px !important;
  }
  .categoriesContainer {
    /* max-width: 95%;
    margin: 0 5% 0 0; */
  }
  .series-seasons-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    /* padding: 20px; */
  }
  .series-episode-list {
    width: 95%;
  }
  .embedButton {
    /* padding: 0px;
    gap: 5px;
    bottom: 35px; */
    padding: 0 0 0 5px;
  }
}
@media (max-width: 550px) {
  .detailsUpperBackground {
    background-size: 100% 116px;
  }
  .episodeDetailsMiddleTop {
    margin: 0;
  }
  .detailsUpper {
    /* height: 116px; */
  }

  .navBarSiteLogo {
    height: 30px;
  }
  .series-seasons-container {
    width: 95%;
    padding: 0;
  }
  .episode-list-img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    height: 100px;
    width: 130px;
  }
  .episodeDetailsLeft {
    width: 130px;
  }
  .episodeDetailsMiddle {
    margin-left: 5px;
  }

  .episodeDetailsMiddleTop {
    font-size: 12px;
    line-height: 16px !important;
  }
  .seasonsAndEpisodesContainer {
    width: 100%;
  }
}

.ItemImage_ViewedSectionPrimary__16dX8 {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ItemImage_ViewedSectionSecondary__1fO2u {
  width: 80%;
  margin: auto;
}

.ItemImage_ViewedSectionSecondary__1fO2u h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
}
.ItemImage_ViewedSectionSecondary__1fO2u h2:hover {
  color: var(--mainYellow);
}
.ItemImage_ViewedSectionSecondary__1fO2u h2:hover::after {
  background-color: var(--mainBlack);
}

.ItemImage_categoryList__3AM3n {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ItemImage_ViewedSectionSecondary__1fO2u .ItemImage_categoryList__3AM3n ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ItemImage_categoryList__3AM3n ul li {
  color: #999999;
  font-size: 0.75rem;
  padding-top: 12px;
}
.ItemImage_ViewedSectionSecondary__3videos__3VvDp {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}

.ItemImage_ViewedSectionSecondary__3videos__image__9T61V,
.ItemImage_ViewedSectionSecondary__2videos__image__1htTM {
  /* margin-bottom: 10px; */
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid #161eaf;
}
.ItemImage_ViewedSectionSecondary__3videos__image__9T61V img {
  width: 100%;
  height: 100%;
  /* border-radius: 16px; */
}

.ItemImage_ViewedSectionSecondary__3videos__image_overlay__14JnO {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ItemImage_wrapper_bottom__1qmdc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ItemImage_wrapper_bottom_likes__2guzn,
.ItemImage_wrapper_bottom_duration__324fY {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-size: 0.75rem;
  font-weight: 800;
}

.ItemImage_wrapper_bottom_likes__2guzn {
  margin-left: 10px;
}
.ItemImage_wrapper_bottom_duration__324fY {
  margin-right: 10px;
}
.ItemImage_ViewedSectionSecondary__2videos__32RZ6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ItemImage_secondaryClassName__3Jlay {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemImage_ViewedSectionSecondary__3videos__main__1Kj7o img,
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU img {
  transition: 0.6s ease;
  width: 100%;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C {
  position: absolute;
  background: var(--cardbackground);
  color: var(--white);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  /* border-radius: 16px; */
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay_blur__2FA8L {
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

/* // animation of play button */
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C:hover {
  opacity: 1;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__2wBoc {
  cursor: pointer;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__2wBoc > * + * {
  margin-top: 20px;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__3eEkW {
  background: var(--brand);
  color: var(--white);
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__3eEkW:hover {
  background: var(--brandHover);
}

.ItemImage_ViewedSectionSecondary__2videos__main__20uAU img {
  /* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__image__1htTM:hover,
.ItemImage_ViewedSectionSecondary__3videos__image__9T61V:hover {
  cursor: pointer;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__1spCt {
  display: flex;
  flex-direction: column;

  grid-gap: 4px;

  gap: 4px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_heading__2RFvh {
  font-weight: 500;
  color: var(--white);
  line-height: 20.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_duration__2IGvx {
  display: flex;
  font-weight: 400;
  color: var(--silver);
  grid-gap: 10px;
  gap: 10px;
  line-height: 16.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__1spCt:hover {
  /* color: var(--mainYellow); */
  cursor: pointer;
}

.ItemImage_ViewedSectionSecondary__3videos__text_actions__3iKKA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 0.875rem;
  color: #999999;
}
.ItemImage_ViewedSectionSecondary__3videos__text_actions__3iKKA p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.ItemImage_ViewedSectionSecondary__3videos__text_description_status__J3iUC {
  color: var(--brand);
  line-height: 15.4px;
  font-weight: 400;
  font-size: var(--font11);
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_status__J3iUC:hover {
  color: var(--brandHover);
}

/* media Queeries */
@media (max-width: 750px) {
  .ItemImage_ViewedSectionSecondary__1fO2u {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

/* Editor chice icon */
.ItemImage_editorChoice__3PBlk {
  top: 0;
  position: absolute;
}
.ItemImage_editorChoice__3PBlk img {
  width: 40px !important;
  height: auto !important;
  border-radius: 0 !important;
  z-index: 100 !important;
}

.ItemTitle1_divider__6Z_q2 {
  margin-right: 5px;
  margin-left: 5px;
}

.ItemTitle1_ViewedSectionPrimary__1g-O2 {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ItemTitle1_ViewedSectionSecondary__1wEfP {
  width: 80%;
  margin: auto;
}

.ItemTitle1_ViewedSectionSecondary__1wEfP h2 {
  text-align: center;
  padding-top: 40px;
}
.ItemTitle1_ViewedSectionSecondary__1wEfP h2:hover {
  color: var(--mainYellow);
}
.ItemTitle1_ViewedSectionSecondary__1wEfP h2:hover::after {
  background-color: var(--mainBlack);
}

.ItemTitle1_categoryList__2qoAP {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ItemTitle1_ViewedSectionSecondary__1wEfP .ItemTitle1_categoryList__2qoAP ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ItemTitle1_categoryList__2qoAP ul li {
  color: #999999;
  padding-top: 12px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__zcnnJ {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA,
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* min-height: 190px; */
}
.ItemTitle1_ViewedSectionSecondary__3videos__image_overlay__2dfgK {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ItemTitle1_wrapper_bottom__Ok69i {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ItemTitle1_wrapper_bottom_likes__sOgzz,
.ItemTitle1_wrapper_bottom_duration__45zjU {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-weight: 800;
}

.ItemTitle1_wrapper_bottom_likes__sOgzz {
  margin-left: 10px;
}
.ItemTitle1_wrapper_bottom_duration__45zjU {
  margin-right: 10px;
}
.ItemTitle1_ViewedSectionSecondary__2videos__3-191 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ItemTitle1_secondaryClassName__2NhmY {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemTitle1_ViewedSectionSecondary__3videos__main__5f4gx img,
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy img {
  transition: 0.6s ease;
  width: 100%;
}
.ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy img {
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr:hover,
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA:hover {
  cursor: pointer;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__oaCJV {
  display: flex;
  flex-direction: column;

  grid-gap: 20px;

  gap: 20px;
  /* padding: 30px 25px; */
  padding: 15px 12.5px;
  background-color: white;
  justify-content: space-around;
  height: 150px;
  font-family: 'Russo One', sans-serif;
  padding-top: 20px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__1GeMu {
  font-weight: 500;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__1kJ8z {
  /* display: flex; */
  font-weight: 400;
  color: var(--itemTitleDescriptionDuration);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__1kJ8z > * + * {
  margin-right: 5px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__oaCJV:hover {
  cursor: pointer;
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__FSMWe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__FSMWe p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__1GeMu {
  /* color: var(--mainWhite); */
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3ATwD {
  color: var(--brand);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3ATwD:hover {
  color: var(--brandHover);
}

/* media Queeries */
@media (max-width: 750px) {
  .ItemTitle1_ViewedSectionSecondary__1wEfP {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

.CategoryTitle2_CategoryItem_main__Wb_bA {
  margin-bottom: -30px;
}
.CategoryTitle2_CategoryItem_main_info__1vXwJ {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  width: 100%; 
}

 
 

 
.Header_HeaderTitle__2WbA4{
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    font-weight: 800;
    padding: 30px 0px;


}


 
 /* link which is inside ItemLink */
.ItemLink_ItemLink__1w02_ a {
    height: 100%;
    border: none;
    color: var(--mainCategoryViewAll);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    font-weight: 800;
  }
  .ItemLink_ItemLink__1w02_ a:hover {
    transition: all ease 0.4s;
    cursor: pointer;
    color: var(--mainCategoryViewAllHover);
  
  }
 /* which does not include a href */


  .ItemLink_ItemLink__1w02_  {
    height: 100%;
    border: none;
    color: var(--mainCategoryViewAll);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    font-weight: 800;
  }
  .ItemLink_ItemLink__1w02_:hover {
    transition: all ease 0.4s;
    cursor: pointer;
    color: var(--mainCategoryViewAllHover);
  
  }
.CategoryContentSimilar_similarContainer__a1mBa {
  width: 88%;
  margin: 0 auto 1% auto;
}

.DetaillsFolderNames_folderName__IjA5N {
  margin-right: 0.5rem;
  background: var(--darkGray);
  color: var(--blueSilver);
  border-radius: 5px;
  padding: 10px 10px;

  border-radius: 100px;
}
.DetaillsFolderNames_folderName__IjA5N:hover {
  background: var(--brandHover);
  cursor: pointer;
  color: var(--brand);
}

.RenderItemDescription_itemDescription__2c6Nl {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /* padding: 0 15% 0 1%; */
  color: var(--silver);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22.4px;
  line-height: 1.62857;
}

@media (max-width: 550px) {
  .RenderItemDescription_itemDescription__2c6Nl {
    line-height: 19.6px;
    line-height: 1.62857;
  }
}

.DetailsShare_details_share__KSZIV {
  display: flex;
  /* padding-top: 35px; */
  flex-wrap: wrap;
  width: 100%;
  /* gap: 20px; */
  align-items: center;
  justify-content: flex-start;
}
.DetailsShare_details_share__KSZIV a {
  text-decoration: none;
  color: var(--brand);
}
.DetailsShare_details_share__KSZIV a:hover {
  color: var(--brandHover);
}

.DetailsShare_shareBTN__1ILU3 {
  background-repeat: no-repeat;
  background-size: cover;

  width: 8.57px;
  height: 16px;
  cursor: pointer;
  margin-right: 10px;
  color: var(--brand);
  display: flex;
  justify-content: center;
  align-items: center;
}

.DetailsVideo_details_description_container__28JRq {
  width: 88%;
  margin: 0 auto;
  background-color: var(--card);
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  color: var(--silver);
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.DetailsVideo_details_description_container__28JRq > * + * {
  margin: 23px 0;
}
.DetailsVideo_detailsDescriptionTitleContainer__Fx5_A {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--brand);
  /* padding: 10px; */
  overflow: hidden;
  align-items: center;
}
.DetailsVideo_details_description_title_name__2YtKs {
  /* text-transform: uppercase; */
  font-weight: bold;
  color: var(--brand);
  line-height: 33.6px;
}

.DetailsVideo_details_description_title_left__jxMoq {
  flex: 2 1;
  margin: 23px 0;
}
.DetailsVideo_details_description_title_right__1E_VL {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .DetailsVideo_details_description_container__28JRq {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .DetailsVideo_details_description_container__28JRq {
    width: 95%;
    padding: 15px;
  }
}

.ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
  width: 88%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 5%;
}

.ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

/* media Queeries */
@media (max-width: 950px) {
  .ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
    width: 100%;
    margin: 0 10px;
  }
}
@media (max-width: 750px) {
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 350px));
  }
}
@media (max-width: 550px) {
  .ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
    width: 80%;
    margin: 0 auto;
  }
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  }
}

.CategoriesWithTitles_categoriesContainer__3s21F {
  width: 100%;
  margin: 0 auto 0 auto;
}
.ListAllSeries_allSeriesContainer__3VyK1 {
  width: 95%;
  margin: 0 0 0 5%;
}

.StateView_stateViewContainer__3I9AW {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.StateView_stateView__R78qc {
  border-bottom: 2px solid var(--mainBlack);
  color: var(--mainBlack);
  text-align: center;
  width: calc(33% - 1em);
  font-size: 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StateView_stateView__R78qc:not(:last-child)::before {
  display: block;
  content: ' \003E';
  height: 1em;
  width: 1em;
  line-height: 1em;
  border-radius: 100%;
  margin-bottom: 0.25rem;
  text-align: center;
  z-index: 1;
  font-size: 18px;
  border: 4px solid;
  padding: 4px;
  position: absolute;
  bottom: -20px;
  /* right: -30px; */
  right: calc(33% - (33% + 2em));
  color: var(--gray);
}
.StateView_stateViewSelected__18XcE:not(:last-child)::before {
  color: var(--brand);
}

.StateView_stateView__R78qc:hover {
  cursor: pointer;
}

.StateView_stateViewSelected__18XcE {
  border-bottom: 2px solid var(--brand);
  color: var(--brand);
}

.PackageComponent_packageComponent__15Hpt {
  width: 28%;
  min-width: 300px;
  border: 2px solid var(--brand);
  border-radius: 4px;
  /* margin: 0px 2% 2%; */
  background-color: var(--mainWhite);
  height: 550px;
  padding: 20px 10px;
  position: relative;
}

.PackageComponent_packageTitle__ybtPr {
  color: var(--brand);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.PackageComponent_packagePrice__pskma {
  color: var(--brand);
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 20px;
  padding: 35px 0;
  word-wrap: break-word;
}

.PackageComponent_packageDescription__3Sp-S {
  text-align: center;
  /* font-size: 1rem; */
  color: var(--mainBlack);
  /* height: 200px; */
  min-height: 250px;
}

.PackageComponent_packageBuyButtonContainer__15Usn {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackageComponent_packageBuyButton__1hjhi {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
}

.PackageComponent_packageBuyButton__1hjhi:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PackageComponent_packageBoughtButton__2Bp-p {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  display: inline-block;
}

.PackageComponent_popupEmailForm__2z5Gt {
  width: 100%;
}
.PackageComponent_popupEmailForm__2z5Gt input {
  padding: 11px 10px;
  border: none;
  /* background: #12161d; */
  color: var(--mainBlack);
  /* font-family: 'Gotham Greek Light', sans-serif !important; */
  /* // safari support */

  -webkit-appearance: initial;
}
.PackageComponent_emailConfirmModalButton__3WQCQ{

  display: flex;
  align-items: center;
}

.PackageComponent_emailConfirmModalButton__3WQCQ *+*{
  margin-left: 10px;
}
/* for the background color of the form and image */
.Input_iconColor__394WK {
  /* font-size: 1rem; */
  width: 100%;
  height: 100%;
  font-size: large;
}
.Input_formPrimary__WAqML {
  background-color: var(--darkBlue);
  width: 100vw;
  height: 100vh;
}
.Input_formSignUpPrimary__1bdNV {
  background-color: var(--darkBlue);
  width: 60%;
}
/* // for image and form to align flex */
.Input_formSecondary__2qVoJ {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Input_formProfilePrimary__ViTp3 {
  display: flex;
  width: 100%;
  background-color: var(--darkBlue);
}
/* // for image and form to align flex */
.Input_formProfileSecondary__2o0gf {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

/* // image  */
.Input_formSecondary__2qVoJ img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
/* main form */

.Input_form__szJBJ {
  width: 70%;
  height: auto;
  margin: 10% 15%;
  border: 2px solid whitesmoke;
  background-color: white;
  padding: 20px;
}

/* //form blog is for big text area input like creatign blogs */
.Input_formBlog__QnvY0 {
  width: 50%;
  height: auto;
  margin: 5% 20%;
  border: 2px solid whitesmoke;
  background-color: white;
  padding: 20px;
}

.Input_formProfile__2gVtn {
  height: auto;
  border: 2px solid whitesmoke;
  background-color: white;
  padding: 20px;
  width: 50%;
}

/* // input of form */
.Input_formSecondary__2qVoJ input,
.Input_formSecondary__2qVoJ textarea {
  padding: 11px 10px;
  border: none;
}

.Input_formSecondary__2qVoJ textarea::-webkit-input-placeholder, .Input_formSecondary__2qVoJ input::-webkit-input-placeholder {
  font-family: serif;
  font-family: initial;
  font-size: medium;
  font-size: initial;
}

.Input_formSecondary__2qVoJ textarea:-ms-input-placeholder, .Input_formSecondary__2qVoJ input:-ms-input-placeholder {
  font-family: serif;
  font-family: initial;
  font-size: medium;
  font-size: initial;
}

.Input_formSecondary__2qVoJ textarea::placeholder,
.Input_formSecondary__2qVoJ input::placeholder {
  font-family: serif;
  font-family: initial;
  font-size: medium;
  font-size: initial;
}
/* // focus of input */
.Input_formSecondary__2qVoJ input:focus,
.Input_formSecondary__2qVoJ textarea:focus {
  outline: none;
}
/* // icon and input together */

.Input_formInput__2a6Jo {
  width: 80%;
  background: rgba(31, 35, 35, 0.03);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid rgba(31, 94, 180, 0.22);
  border-radius: 4px;
  margin: 30px auto;
}

/* // invalid form input */
.Input_formInputInvalid__3trEQ {
  background-color: var(--red);
  color: white;
}
/* // focusing the whole icon and input when focused upon inpu */
.Input_formInput__2a6Jo:focus-within {
  background-color: rgba(51, 12, 158, 0.151);
  border: 1px solid blue;
}

/* // h2 */
.Input_formHeader__2KD_k {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}

.Input_formHeader__2KD_k::after {
  height: 4px;
  display: block;
  content: '';
  width: 120px;
  background-color: var(--mainBlack);
  margin: 20px auto 0 auto;
}

/* // link textxx */
.Input_linkText__1_0_U {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 80%;
  margin-top: 20px;
}
/* // for image file button margin */
.Input_formInputImageUploadInput__25fhW {
  margin-left: 20px;
}

/* // error text */
.Input_errorText__15zRO {
  color: var(--red);
  /* font-size: 80%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
}
.Input_errorText__15zRO::before {
  /* display: inline; */
  content: '⚠  \00a0  ';
}

@media (max-width: 799px) {
  .Input_form__szJBJ {
    width: 60%;
    height: auto;
    margin: 10% 20%;
    border: 2px solid white;
    background-color: white;
    padding: 20px;
  }
  .Input_formSecondary__2qVoJ img {
    display: none;
  }
  .Input_formBlog__QnvY0 {
    width: 80%;
    height: auto;
    margin: 10%;
  }
}
@media (max-width: 1025px) {
  .Input_formSecondary__2qVoJ img {
    width: 30%;
  }
  .Input_formBlog__QnvY0 {
    width: 80%;
    margin: 10%;
  }
  .Input_form__szJBJ {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .Input_formSecondary__2qVoJ img {
    display: none;
  }
  .Input_form__szJBJ {
    border: 2px solid white;
    background-color: white;
    padding: 20px;
  }
  .Input_formInput__2a6Jo {
    width: 100%;
  }
  .Input_formBlog__QnvY0 {
    width: 90%;
    margin: 5%;
  }
  .Input_formSecondary__2qVoJ {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .Input_form__szJBJ {
    border: 2px solid white;
    background-color: white;
    padding: 20px;
    margin: 0;
  }
  .Input_formBlog__QnvY0 {
    margin: 2.5%;
    width: 95%;
  }

  .Input_formInput__2a6Jo {
    width: 100%;
  }
  .Input_formPrimary__WAqML {
    height: 70vh;
    padding-bottom: 5rem;
  }
}

.button {
     
color: white;
cursor: pointer;

 padding: 10px 56px;
 text-decoration: none;
 border: none;
  font-size: var(--font16);
  border-radius: 12px;
  background-color:var( --navBarBackgroundColor);
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
  background-color:var( --brand);


  }
  
  .button--inverse {
    background: var(--mainWhite);
    color: var(--brand);
    border: 1px solid var(--brand);
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: var(--mainWhite);
  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  
.PackagesView_packagesView__9yr1V {
}

.PackagesView_packages__-3W1Y {
  width: 88%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;
}

.PackagesView_packageHelp__1iO3u {
  width: 88%;
  margin: 0 auto;
}

.PackagesView_packageHelpTitle__MBEaA {
  color: var(--mainBlack);
  font-size: 1.5rem;
  /* width: 88%; */
  margin: 0 auto;
  text-align: left;
}

.PackagesView_packageHelpText__1uwLf {
  color: var(--mainBlack);
  /* width: 88%; */
  margin: 50px auto;

  padding: 30px 30px;
  background-color: whitesmoke;
}

.PaymentMethod_paymentMethodContainer__TCANk {
  width: 100%;
}

.PaymentMethod_paymentMethodBuy__1d2A0 {
  cursor: pointer;
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
}

.PaymentMethod_paymentMethodImage__2TCby {
  max-height: 80px;
  max-width: 100%;
}

.SelectedPackage_selectedPackage__2JAfO {
  width: 80%;
  margin: 10px auto;
  text-align: left;
  font-size: 1rem;
  color: var(--mainBlack);
  display: table;
}

.SelectedPackage_selectedPackageRow__Jgnt8 {
  display: table-row;
}

.SelectedPackage_selectedPackageCol__1HpDN {
  display: table-cell;
}

.SelectedPackage_selectedPackageName__3Bs7d {
  white-space: nowrap;
  display: table-cell;
  width: 20%;
}

.SelectedPackage_selectedPackagePrice__3Nk28 {
  white-space: nowrap;
  display: table-cell;
}

.SelectedPackage_selectedPackageVat__3TNl8 {
  white-space: nowrap;
  display: table-cell;
}

.VoucherView_voucherTitle__1Vapu {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.VoucherView_voucherButtons__319oi {
  margin: 0 auto;
  display: flex;
}

.VoucherView_voucherButtons__319oi * +*{
  margin-left: 10px;
}

 
 

.VoucherView_voucherApiError__-H_sv {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--red);
}
.VoucherView_voucherInput__1kawt input{
  padding: 11px 10px;
}
.PaymentMethodsView_payment__2jlDa {
  width: 80%;
  margin: 30px auto;
}

.PaymentMethodsView_paymentTitle__1jBp3 {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.PaymentMethodsView_paymentHelp__PeufQ {
}

.PaymentMethodsView_paymentUserNotLoggedIn__bcyJf {
  padding: 20px 0 0;
  color: var(--red);
}

.PaymentMethodsView_paymentMethodsContainer__2cLOx {
  margin-top: 30px;
  position: relative;
}

.PaymentMethodsView_paymentHideMethods__3blZh {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
}

.PaymentMethodsView_paymentMethodsTitle__1zQ2O {
  width: 80%;
  margin: 30px auto 10px;
  color: var(--mainBlack);
  font-size: 1.5rem;
  text-align: left;
}

.PaymentMethodsView_paymentMethodHelp__1bbXH {
  width: 80%;
  margin: 0 auto;
}

.PaymentMethodsView_paymentMethodError__OrQPi {
  width: 80%;
  margin: 0 auto;
  color: var(--red);
}

.PaymentMethodsView_paymentMethods__3bpEP {
  width: 80%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mainBlack);
  padding: 20px 20px 10px;
}

.ReceiptView_receiptView__CP4zq {
  width: 80%;
  margin: 30px auto;
}

.ReceiptView_receiptTitle__-ckaD {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.ReceiptView_receiptText__13TDS {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptFailureMessage__1K-4U {
  width: 80%;
  margin: 0 auto;
  color: var(--red);
}

.ReceiptView_receiptReturnPath__4zHUT {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptReturnButton__3TBZK {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
}

.ReceiptView_receiptReturnButton__3TBZK:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PurchaseFlow_packageMain__1HUS5 {
  width: 100%;
  display: block;
}

.PurchaseFlow_packages__1qzoZ {
  width: 88%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PurchaseFlow_pageState__3vaOh {
  width: 88%;
  margin: 0 auto;
}

.Events_container_2videos__1E4lz {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
 

 

 
 
.Events_Events_main_info__Eu18s {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}



.Events_Events_main_info__Eu18s h2 {
  padding: 10px 30px;
}

.Events_Events_main__3GBUi h2::after {
  display: none;
}
.Events_container_3videos__AUTnF {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
.Events_container_homePage__11NB- {
  width: 95%;
}
.Events_EventTitle__AA4re {
  color: var(--itemTitle);
  font-family: 'Russo One', sans-serif;
}

.Events_Events__1G-ma {
  background-color: white;
  border-radius: 10px;
}
.Events_Events__1G-ma:hover {
  cursor: pointer;
}
.Events_Event__1s27a {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--brand);
  padding-bottom: 40px;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
}
.Events_EventTitle__AA4re {
  padding-bottom: 10px;
}

.Events_EventInfo__3hpQ7 {
  color: var(--mainYellow);
  padding-bottom: 20px;
  font-weight: 700;
}
.Events_EventDuration__1dDPn {
  margin-top: 10px;
  font-weight: 700;
}
.Events_EventInfo__3hpQ7,
.Events_EventTitle__AA4re,
.Events_EventDuration__1dDPn {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.Events_EventInfo__3hpQ7 > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__AA4re > * + * {
  margin-left: 10px !important;
}
.Events_EventDuration__1dDPn > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__AA4re:hover {
  text-decoration: underline;
  color: var(--brand);
}
.Events_icon__1fDAN {
}

.Events_event__2YtBx {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.Events_event__2YtBx h2 {
  color: var(--mainWhite);
  padding-bottom: 0;
}
.Events_iframe__2O-cM {
  /* height: 80vh; */
  border: none;
  margin: 10px 0;
}

.Events_ViewedSectionSecondary__2videos__3DHdJ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.Events_ViewedSectionSecondary__3videos__3WbiD {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  
}
.Events_NoDataAvailable__3Q7MW {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 0 30px 0;
}

.Events_NoDataAvailable__3Q7MW h3 {
  color: var(--brand);
}
.Events_NoDataAvailable__3Q7MW img {
  width: 400px;
}

.Events_modalItem__3W5V_ {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--mainWhite);
}
.Events_modalItem_button__2sTw2 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}
.Events_modalItem_button__2sTw2 button {
}
.Events_modalItem_button_confirm__lnht- {
  background-color: var(--mainCategoryViewAll);
  color: var(--mainWhite);
}
.Events_modalItem_button_confirm__lnht-:hover {
  background-color: var(--mainCategoryViewAllHover);
  color: var(--mainWhite);
}
.Events_modalItem_button_cancel__3pT7E:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil,
.Events_Events_main_info_filter_removeFilter__21mUA {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Events_Events_main_info_filter_addFilter__2ueil {
  background-color: var(--mainCategoryViewAll);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil:hover .Events_filterIcon__3THkZ {
  background-color: var(--mainYellow);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil:hover {
  background-color: var(--mainCategoryViewAllHover);

  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__21mUA:hover .Events_removeIcon__17aE5 {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__21mUA:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
/* filter icon */
.Events_filterIcon__3THkZ {
  color: var(--mainYellow);
}
.Events_filterIcon__3THkZ:hover {
  color: var(--mainWhite);
  cursor: pointer;
}
.Events_removeIcon__17aE5 {
  color: var(--red);
}

.Events_Events_main_info_button__1-pBM {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Events_Events_main_info_header__1BGv9 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1050px) {
  .Events_container_2videos__1E4lz {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .Events_ViewedSectionSecondary__2videos__3DHdJ {
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
  .Events_container_2videos__1E4lz {
    width: 100%;
  } 
  .Events_Events_main_info__Eu18s h2 {
    padding: 20px 10px;
  }
  .Events_NoDataAvailable__3Q7MW img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .Events_container_2videos__1E4lz {
    width: 88%;
    margin: 0 auto;
  }
  .Events_NoDataAvailable__3Q7MW img {
    width: 200px;
  }
}

.EventsItem_ViewedSectionPrimary__3uGyc {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
/* .ViewedSectionSecondary {
    width: 80%;
    margin: auto;
  } */

.EventsItem_ViewedSectionSecondary__3KQib h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
  color: var(--mainBlack);
}
.EventsItem_ViewedSectionSecondary__3KQib h2:hover {
}
.EventsItem_ViewedSectionSecondary__3KQib h2::after {
  background-color: var(--mainBlack);
}

.EventsItem_categoryList__JjYS- {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.EventsItem_ViewedSectionSecondary__3KQib .EventsItem_categoryList__JjYS- ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.EventsItem_categoryList__JjYS- ul li {
  color: #999999;
  padding-top: 12px;
}
.EventsItem_ViewedSectionSecondary__3videos__2QrYq {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.EventsItem_ViewedSectionSecondary__3videos__image__2ozAr,
.EventsItem_ViewedSectionSecondary__2videos__image__3w1Ii {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}
.EventsItem_ViewedSectionSecondary__3videos__image__2ozAr img {
  width: 100%;
  height: 100%;
  /* min-height: 190px; */
}
.EventsItem_ViewedSectionSecondary__3videos__image_overlay__RDj96 {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.EventsItem_wrapper_bottom__3SbUJ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.EventsItem_wrapper_bottom_likes__2EIWT,
.EventsItem_wrapper_bottom_duration__xAe0x {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.EventsItem_wrapper_bottom_likes__2EIWT {
  margin-left: 10px;
}
.EventsItem_wrapper_bottom_duration__xAe0x {
  margin-right: 10px;
}

.EventsItem_wrapper_bottom_likes__2EIWT > * + * {
  margin-right: 10px;
}
.EventsItem_ViewedSectionSecondary__2videos__2cyJd {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.EventsItem_secondaryClassName__3zO-c {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.EventsItem_ViewedSectionSecondary__3videos__main__2fvqh img,
.EventsItem_ViewedSectionSecondary__2videos__main__3WYYL img {
  transition: 0.6s ease;
  width: 100%;
}
.EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__xdgts {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.699); */
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.EventsItem_ViewedSectionSecondary__2videos__image__3w1Ii:hover
  .EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__xdgts {
  opacity: 1;
}
.EventsItem_ViewedSectionSecondary__3videos__image__2ozAr:hover
  .EventsItem_ViewedSectionSecondary__3videos__videoicon_overlay__xdgts {
  opacity: 1;
}
.EventsItem_ViewedSectionSecondary__2videos__main__3WYYL img {
  /* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.EventsItem_ViewedSectionSecondary__2videos__main__3WYYL:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.EventsItem_ViewedSectionSecondary__2videos__main__3WYYL:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.EventsItem_ViewedSectionSecondary__2videos__image__3w1Ii:hover,
.EventsItem_ViewedSectionSecondary__3videos__image__2ozAr:hover {
  cursor: pointer;
}
.EventsItem_ViewedSectionSecondary__3videos__text_description__3Ig5w {
  text-align: center;
}
.EventsItem_ViewedSectionSecondary__3videos__text_description__3Ig5w:hover {
  color: var(--mainYellow);
  cursor: pointer;
}

.EventsItem_ViewedSectionSecondary__3videos__text_actions__3GQZS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.EventsItem_ViewedSectionSecondary__3videos__text_actions__3GQZS p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.EventsItem_Events_Image__Lxh_f img {
  height: 200px;
  max-height: 200px;
  min-height: 200px;
}

/* media Queeries */
@media (max-width: 750px) {
  /* .ViewedSectionSecondary {
      width: 100%;
      margin: 0 10px 0 10px;
    } */
}
@media (max-width: 550px) {
}

.ViewedSection_ViewedSectionPrimary__3EHlY {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ViewedSection_ViewedSectionSecondary__M0doS {
  width: 80%;
  margin: auto;
}

.ViewedSection_ViewedSectionSecondary__M0doS h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: 400;
}
.ViewedSection_ViewedSectionSecondary__M0doS h2:hover {
  color: var(--mainYellow);
}
.ViewedSection_ViewedSectionSecondary__M0doS h2:hover::after {
  background-color: var(--mainBlack);
}

.ViewedSection_categoryList__284Q4 {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ViewedSection_ViewedSectionSecondary__M0doS .ViewedSection_categoryList__284Q4 ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ViewedSection_categoryList__284Q4 ul li {
  color: #999999;
  font-size: 0.75rem;
  padding-top: 12px;
}
.ViewedSection_ViewedSectionSecondary__3videos__IgR9v {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.ViewedSection_ViewedSectionSecondary__3videos__image__1KOVn,
.ViewedSection_ViewedSectionSecondary__2videos__image__29T7r {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.ViewedSection_ViewedSectionSecondary__3videos__image__1KOVn img {
  width: 100%;
  height: 100%;
  /* min-height: 190px; */
}
.ViewedSection_ViewedSectionSecondary__3videos__image_overlay__1KxiU {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ViewedSection_wrapper_bottom__1dT-C {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ViewedSection_wrapper_bottom_likes__2W3nA,
.ViewedSection_wrapper_bottom_duration__2iby2 {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-size: 0.75rem;
  font-weight: 800;
}

.ViewedSection_wrapper_bottom_likes__2W3nA {
  margin-left: 10px;
}
.ViewedSection_wrapper_bottom_duration__2iby2 {
  margin-right: 10px;
}
.ViewedSection_ViewedSectionSecondary__2videos__7ltyF {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ViewedSection_secondaryClassName__26T6Z {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ViewedSection_ViewedSectionSecondary__3videos__main__2Gvpr img,
.ViewedSection_ViewedSectionSecondary__2videos__main__2vgnb img {
  transition: 0.6s ease;
  width: 100%;
}
.ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__19yqr {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ViewedSection_ViewedSectionSecondary__2videos__image__29T7r:hover
  .ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__19yqr {
  opacity: 1;
}
.ViewedSection_ViewedSectionSecondary__3videos__image__1KOVn:hover
  .ViewedSection_ViewedSectionSecondary__3videos__videoicon_overlay__19yqr {
  opacity: 1;
}
.ViewedSection_ViewedSectionSecondary__2videos__main__2vgnb img {
  /* height: 350px;
  min-height: 350px;
  max-height: 350px; */
}
.ViewedSection_ViewedSectionSecondary__2videos__main__2vgnb:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ViewedSection_ViewedSectionSecondary__2videos__main__2vgnb:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ViewedSection_ViewedSectionSecondary__2videos__image__29T7r:hover,
.ViewedSection_ViewedSectionSecondary__3videos__image__1KOVn:hover {
  cursor: pointer;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_description__1JBe9 {
  font-size: 0.75rem;
  font-weight: 600;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_description__1JBe9:hover {
  color: var(--mainYellow);
  cursor: pointer;
}

.ViewedSection_ViewedSectionSecondary__3videos__text_actions__3oFqD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 0.875rem;
  color: #999999;
}
.ViewedSection_ViewedSectionSecondary__3videos__text_actions__3oFqD p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

/* media Queeries */
@media (max-width: 750px) {
  .ViewedSection_ViewedSectionSecondary__M0doS {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

.UpcomingEvent_EventPrimary__fGcNZ {
  width: 100%;
  margin: 0 auto;
  background-color: var(--mainWhite);
  color: var(--silver);
  margin-top: 50px;
  padding: 30px;
}
.UpcomingEvent_EventSecondary__3LY4h {
  width: 88%;
  margin: 0 auto;
}
.UpcomingEvent_SecondaryContainer__3sQfS {
  background-color: black;
  width: 88%;
  margin: 0 auto;
  padding: 20px 10px;
}
.UpcomingEvent_EventTertairy__2zGMq {
  width: 88%;

  margin: 0 auto;
}
.UpcomingEvent_EventPrimary__fGcNZ > * {
  padding-bottom: 10px;
}

.UpcomingEvent_EventPrimary_title__cFiC_ {
  padding: 50px 0 20px 0;
  color: var(--itemTitle);
}
h1::after,
h2::after {
  display: none;
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ {
  /* font-size: 2.5rem; */
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ > * + * {
  margin-left: 20px;
}

.UpcomingEvent_EventPrimary_countdown__2f_FJ span {
  padding: 20px;
  background-color: var(--itemTitle);
  color: var(--mainWhite);
}
.UpcomingEvent_EventPrimary_countdown_description__9qBaX {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UpcomingEvent_EventPrimary_countdown_description__9qBaX > * + * {
  margin-top: 10px;
}

.UpcomingEvent_EventPrimary_countdown_description__9qBaX p,
.UpcomingEvent_EventPrimary_date__3VZFF,
.UpcomingEvent_EventPrimary_time__2Y9SJ {
  /* font-size: 1.3rem; */
}

.UpcomingEvent_EventPrimary_date__3VZFF,
.UpcomingEvent_EventPrimary_time__2Y9SJ {
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
}
.UpcomingEvent_EventPrimary_date__3VZFF > * + * {
  margin-left: 20px;
}
.UpcomingEvent_EventPrimary_time__2Y9SJ > * + * {
  margin-left: 20px;
}
.UpcomingEvent_EventTimeDescription__3GpLq > * + * {
  margin-top: 20px;
}
.UpcomingEvent_EventTimeDescription__3GpLq {
  margin-top: 60px;
  margin-bottom: 50px;
  color: var(--brand);
}

.UpcomingEvent_EventPrimary_description__3z1Ob > * {
  margin: 30px 0;
  line-height: 200%;
}
.UpcomingEvent_EventPrimary_description__3z1Ob span,
.UpcomingEvent_EventPrimary_description__3z1Ob p,
.UpcomingEvent_EventPrimary_description__3z1Ob h1 h2 h3 {
  background-color: white !important;
  color: var(--mainBlack) !important;
}
.UpcomingEvent_EventPrimary_description__3z1Ob a {
  text-decoration: none;
  color: var(--brand);
}
.UpcomingEvent_EventPrimary_description__3z1Ob a:hover {
  text-decoration: underline;
  color: var(--brandHover);
}
.UpcomingEvent_EventPrimary_description__3z1Ob p {
  font-size: 1.1rem;
}

/* // ticket */
.UpcomingEvent_buyTicket__HzJ0n {
  display: flex;
  align-items: center;
  /* width: 88%; */
  /* margin: 0 auto; */
}
.UpcomingEvent_buyTicket__HzJ0n button {
  line-height: 150%;
  /* background-color: var(--brand); */
  /* color: var(--mainWhite); */
  /* height: 40px;
  max-height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.UpcomingEvent_buyTicket__HzJ0n > * + * {
  margin-left: 100px;
}
.UpcomingEvent_buyTickets_info_input_null__IMeQf {
  visibility: hidden;
  opacity: 0;
}
.UpcomingEvent_buyTickets_info__3zZh8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.UpcomingEvent_buyTickets_info__3zZh8 > * + * {
  margin-top: 10px;
}
.UpcomingEvent_buyTickets_info_input__1TILO {
  display: flex;
  justify-content: center;
  align-items: center;
}
.UpcomingEvent_buyTickets_info_input__1TILO > * + * {
  margin-left: 10px;
}
.UpcomingEvent_buyTickets_info_input__1TILO input {
  /* height: 40px; */
  padding: 12px 12px;
  width: 250px;
  /* max-height: 40px; */
}
.UpcomingEvent_buyTickets_info_btn__1-FiR {
  background-color: var(--mainBlack) !important;
}
@media (max-width: 1140px) {
  .UpcomingEvent_SecondaryContainer__3sQfS {
    background-color: black;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 550px) {
  .UpcomingEvent_EventTertairy__2zGMq {
    width: 100%;

    margin: 0 auto;
    padding: 0 20px;
  }
}

.RadiantPlayer_RadiantPlayer__3iTPu {
  /* position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000; */
  width: 100%;
  /* padding: 20px 0; */
  /* margin: 0 auto; */
}
.RadiantPlayer_RadiantPlayer__3iTPu .RadiantPlayer_rmp-control-bar__2AqcS {
  /* margin-top: -200px; */
}

 
.SearchEmbed_resultsAmount__sc781 {
    align-self: center;
    margin-top: 1%;
  }
  
  .SearchEmbed_searchRowNewOrAlphabetContainer__nV2kZ {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .SearchEmbed_searchResultBTN__2h34R {
    color: var(--white);
    background: var(--gray);
    cursor: pointer;
    margin-left: 10px;
    border-radius: 0 !important;
  }
  .SearchEmbed_searchResultBTN__2h34R:hover,
  .SearchEmbed_searchResultBTNactive__3wb51:hover {
    color: var(--brand);
    background: var(--brandHover);
    border-radius: 0 !important;
  }
  .SearchEmbed_form_button__UD9tU {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border-radius: 0 !important;
    /*gap: 10px; stupid safari */
  }

  .SearchEmbed_form_button__UD9tU  > * + * {
    margin-left: 10px;
  }
  
  
  .SearchEmbed_searchUpper__2gqQ3 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .SearchEmbed_searchResultBTNactive__3wb51 {
    color: var(--white);
    cursor: pointer;
    background: var(--brand);
    margin-left: 10px;
    border-radius: 0 !important;
  }
  
  .SearchEmbed_searchLabel__12UWp {
    margin-bottom: 0.25rem;
  }
  
  .SearchEmbed_searchCheckbox__2FMho {
    margin-right: 0.25rem;
  }
  
  .SearchEmbed_searchCheckboxLabel__GA-x6 {
    display: flex;
    width: 100%;
    align-items: center;
    margin-right: 1%;
    white-space: nowrap;
  }
  
  .SearchEmbed_searchSelectCategory__2I73_ {
    padding: 0.25rem;
  }
  
  .SearchEmbed_searchContainer__3Q4z5 {
    display: flex;
    flex-direction: column;
    color: var(--mainBrand);
    
    margin: 0 auto;
    padding: 20px 0;
  width: 88%;
  margin: 0 auto;
    
  }
  .SearchEmbed_searchContainer_back_icon__3c0Ov{
      display: flex;
      align-items: center;
  }
  .SearchEmbed_row__3huYc {
    display: flex;
    color: var(--mainBrand);
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
  }
  
  .SearchEmbed_displayFlexCenter__2g4fA {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SearchEmbed_searchColumn__3L5zH {
    display: flex;
    /* flex-direction: column; */
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainWhite);
    width: 400px;
    outline: 0.1px solid var(--brand);
  }
  .SearchEmbed_FaIconSearch__3rl-o:hover{
      cursor: pointer;
      color: var(--mainCategoryViewAllHover);

  }
  .SearchEmbed_bottomContainer__2I5QK {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  .SearchEmbed_searchButton__3cdYu {
    color: var(--white);
    background-color: var(--brand);
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 0 !important;
  }
  
  .SearchEmbed_FaIcon__1leB6 {
    margin-right: 5px;
  }
  .SearchEmbed_searchContainer_back__1SdgI{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .SearchEmbed_searchButton__3cdYu:hover {
    color: var(--brand);
    background-color: var(--brandHover);
  }
  
  .SearchEmbed_searchField__3aEEM {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    /*border-radius: 2px;*/
    flex: 4 1;
    border: none;
    outline: none;
  }
  
  .SearchEmbed_searchRow__1C73S {
    margin-bottom: 1%;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;

  }
  .SearchEmbed_search_form_label__21p-Z {
    display: flex;
  }
  #SearchEmbed_searchForm__2XpIv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  .SearchEmbed_searchTitle__2PB34 {
    margin-bottom: 2rem;
  }
  
  .SearchEmbed_resultsGrid__y2k-Q {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
    align-items: center;
    justify-content: center;
    grid-column-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-top: 1%;
  }
  @media (max-width: 1180px) {
    .SearchEmbed_searchRow__1C73S {
      width: 100%;
    }
    .SearchEmbed_search_form_label__21p-Z{
      margin-bottom: 2%;
    }
    .SearchEmbed_searchRowNewOrAlphabetContainer__nV2kZ{
      flex-direction: column;
    }
  }
  
  @media (max-width: 700px) {
    .SearchEmbed_searchContainer__3Q4z5 {
      /* width: 95%; */
      padding: 20px 20px;
    }
  }
  @media (max-width: 700px) {
    .SearchEmbed_searchButton__3cdYu,
    .SearchEmbed_searchResultBTN__2h34R,
    .SearchEmbed_searchResultBTNactive__3wb51 {
      padding: 10px 20px !important;
    }
  }
  
  @media (max-width: 550px) {
    .SearchEmbed_searchContainer__3Q4z5 {
      padding: 5px;
    }
    .SearchEmbed_searchRow__1C73S {
      width: 95%;
      padding: 0px;
      margin: 0 auto;
    }
    .SearchEmbed_row__3huYc {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
  }
  
.SearchHomePage_searchContainer__P4R70 {
    display: flex;
    flex-direction: column;
    color: var(--mainBrand);
    
    margin: 0 auto;
    padding: 20px 0;
  width: 88%;
  margin: 0 auto;
    
  }

.SearchHomePage_searchContainer_back_icon__1-BCZ{
    display: flex;
    align-items: center;
    color: var(--mainCategoryViewAll);
}
.SearchHomePage_searchContainer_back_icon__1-BCZ:hover{
    cursor: pointer;
    color: var(--mainCategoryViewAllHover);

}

.SearchHomePage_searchContainer_back__2Jn0x{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SearchHomePage_FaIcon__2z2p_ {
    margin-right: 12px;
  }

  .SearchHomePage_FaIconSearch__fGNqL:hover{
    cursor: pointer;
    color: var(--mainCategoryViewAllHover);

}

#SearchHomePage_searchForm__3Ydhc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .SearchHomePage_searchRow__19dij {
    margin-bottom: 1%;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;

  }

  .SearchHomePage_searchColumn__2QlzB {
    display: flex;
    /* flex-direction: column; */
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainWhite);
    width: 400px;
    outline: 0.1px solid var(--brand);
  }

  .SearchHomePage_searchField__kD3OZ {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    /*border-radius: 2px;*/
    flex: 4 1;
    border: none;
    outline: none;
  }
