 
.resultsAmount {
    align-self: center;
    margin-top: 1%;
  }
  
  .searchRowNewOrAlphabetContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .searchResultBTN {
    color: var(--white);
    background: var(--gray);
    cursor: pointer;
    margin-left: 10px;
    border-radius: 0 !important;
  }
  .searchResultBTN:hover,
  .searchResultBTNactive:hover {
    color: var(--brand);
    background: var(--brandHover);
    border-radius: 0 !important;
  }
  .form_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border-radius: 0 !important;
    /*gap: 10px; stupid safari */
  }

  .form_button  > * + * {
    margin-left: 10px;
  }
  
  
  .searchUpper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .searchResultBTNactive {
    color: var(--white);
    cursor: pointer;
    background: var(--brand);
    margin-left: 10px;
    border-radius: 0 !important;
  }
  
  .searchLabel {
    margin-bottom: 0.25rem;
  }
  
  .searchCheckbox {
    margin-right: 0.25rem;
  }
  
  .searchCheckboxLabel {
    display: flex;
    width: 100%;
    align-items: center;
    margin-right: 1%;
    white-space: nowrap;
  }
  
  .searchSelectCategory {
    padding: 0.25rem;
  }
  
  .searchContainer {
    display: flex;
    flex-direction: column;
    color: var(--mainBrand);
    
    margin: 0 auto;
    padding: 20px 0;
  width: 88%;
  margin: 0 auto;
    
  }
  .searchContainer_back_icon{
      display: flex;
      align-items: center;
  }
  .row {
    display: flex;
    color: var(--mainBrand);
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
  }
  
  .displayFlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .searchColumn {
    display: flex;
    /* flex-direction: column; */
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainWhite);
    width: 400px;
    outline: 0.1px solid var(--brand);
  }
  .FaIconSearch:hover{
      cursor: pointer;
      color: var(--mainCategoryViewAllHover);

  }
  .bottomContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  .searchButton {
    color: var(--white);
    background-color: var(--brand);
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 0 !important;
  }
  
  .FaIcon {
    margin-right: 5px;
  }
  .searchContainer_back{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .searchButton:hover {
    color: var(--brand);
    background-color: var(--brandHover);
  }
  
  .searchField {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    /*border-radius: 2px;*/
    flex: 4;
    border: none;
    outline: none;
  }
  
  .searchRow {
    margin-bottom: 1%;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;

  }
  .search_form_label {
    display: flex;
  }
  #searchForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  .searchTitle {
    margin-bottom: 2rem;
  }
  
  .resultsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
    align-items: center;
    justify-content: center;
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-top: 1%;
  }
  @media (max-width: 1180px) {
    .searchRow {
      width: 100%;
    }
    .search_form_label{
      margin-bottom: 2%;
    }
    .searchRowNewOrAlphabetContainer{
      flex-direction: column;
    }
  }
  
  @media (max-width: 700px) {
    .searchContainer {
      /* width: 95%; */
      padding: 20px 20px;
    }
  }
  @media (max-width: 700px) {
    .searchButton,
    .searchResultBTN,
    .searchResultBTNactive {
      padding: 10px 20px !important;
    }
  }
  
  @media (max-width: 550px) {
    .searchContainer {
      padding: 5px;
    }
    .searchRow {
      width: 95%;
      padding: 0px;
      margin: 0 auto;
    }
    .row {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
  }
  